import { TClaimStatus, TProviders, TRateTypes } from '../../common/types';
import { ExPairsDataType } from '../exchangePair-api/classes';

export type GetExchangesResponse = {
  count: number;
  dataList: ClaimDataType[];
};

export type ClaimDataType = {
  id: string;
  frontend_id: string;
  provider_name: string;
  rate_type: TRateTypes;
  status: ClaimStatus;
  exchange_pair: ExPairsDataType;
  changelly_claim: ChangellyClaimDataType | null;
  claim_event_list: ClaimIventDataType[];
};

// todo - remove comment useless fields
export type ChangellyClaimDataType = {
  id: string;
  changelly_id: string;
  status: ClaimStatus;
  payin_address: string;
  payin_extra_id: string;
  payin_hash: string;
  payout_hash: string;
  payout_address: string;
  payout_extra_id: string;
  payout_extra_id_name: string;
  refund_hash: string;
  refund_address: string;
  refund_extra_id: string;
  amount_expected_from: string;
  amount_expected_to: string;
  amount_from: string;
  amount_to: string;
  network_fee: string;
  changelly_fee: string;
  api_extra_fee: string;
  total_fee: string;
};

// todo - check for эффективность может лучше просто строки
export enum ClaimStatus {
  NEW = 'NEW',
  WAIT_DEPOSIT = 'WAIT_DEPOSIT',
  CONFIRM = 'CONFIRM',
  EXCHANGE = 'EXCHANGE',
  SEND = 'SEND',
  FINISH = 'FINISH',
  FAIL = 'FAIL',
  REFUND = 'REFUND',
  HOLD = 'HOLD',
  EXPIRE = 'EXPIRE',
}



export type GetOneExchangeResponse = {
  claimOne: ClaimDataType;
};

export type ClaimIventDataType = {
  id: string;
  title: string;
  description: string;
  claim_id: string;
  created_at: string;
};

export type getExchangesParams = {
  page: number;
  perPage: number;
  searchValue?: string;
  coinId?: string;
  coinFromId?: string;
  coinToId?: string;
  networkId?: string;
  networkFromId?: string;
  networkToId?: string;
  status?: TClaimStatus;
  providerName?: TProviders;
  dateFrom?: string;
  dateTo?: string;
};

export type postAddCommentToClaimProps = {
  claimId: string;
  comment: string;
};
