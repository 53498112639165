import { useEffect, useReducer, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Content, Header } from 'antd/es/layout/layout';
import { Button, Layout, Table } from 'antd';

import style from './styles/style.module.scss';

import { columns } from './columns';
import InfoModal from './infoModal';
import { Loader } from '../../common/components/Loader';
import { ActionValueTypes, filterReducer, IActionTypes, initialFilters } from './filterReducer';
import { FilterBar } from './components/FilterBar';
import { useModulesContext } from '../../store/ModulesContextProvider';
import { useLocation } from 'react-router-dom';
import { TClearingFilters } from '../clearing/columns';
import { AtLeastOne } from '../../common/types';
import { getExchangesParamsAndFIlters } from './vm';

 export type TImportedFilters = {
  filters: TClearingFilters;
};

const View = () => {
  const { ExchangesStore } = useModulesContext();

  const [loading, setLoading] = useState(true);

  const [filters, dispatch] = useReducer(filterReducer, initialFilters);

  const [pageInfo, setPageInfo] = useState({
    page: 1,
    perPage: 10,
  });
  const [openInfoModal, setOpenInfoModal] = useState(false);

  useEffect(() => {
    const props: getExchangesParamsAndFIlters = {
      ...pageInfo,
      ...filters,
    };
    ExchangesStore.getExchanges(props).then(() => {
      setLoading(false);
    });
  }, [ExchangesStore, pageInfo, filters]);

  const locationState = useLocation().state as TImportedFilters | null;
  useEffect(() => {
    if (locationState) {
      const clearingfilters = locationState.filters;
      dispatch({
        type: 'fromClearing',
        value: { clearingFilters: clearingfilters },
      });
    }
  }, [locationState]);

  function handleFilter(value: AtLeastOne<ActionValueTypes>, filterType: IActionTypes) {
    dispatch({
      type: filterType,
      value: value,
    });
  }

  return (
    <Layout className={style.layout}>
      <Header className={style.layout__header}>
        <h1>Обмены</h1>
        <Button type="primary" size={'large'} onClick={() => setOpenInfoModal(true)}>
          Показать инструкцию
        </Button>
      </Header>
      <Content className={style.layout__content_exchanges}>
        <FilterBar filters={filters} onFilter={handleFilter} />
        <InfoModal open={openInfoModal} onCancel={() => setOpenInfoModal(false)} />
        {loading ? (
          <Loader />
        ) : (
          <Table
            rowKey="id"
            scroll={{ x: 1500 }}
            dataSource={ExchangesStore?.data}
            columns={columns()}
            bordered={true}
            pagination={{
              total: ExchangesStore?.count,
              showSizeChanger: true,
              pageSize: pageInfo.perPage,
              onChange(page, pageSize) {
                setPageInfo({
                  page: page,
                  perPage: pageSize,
                });
              },
            }}
          />
        )}
      </Content>
    </Layout>
  );
};

export const Exchanges = observer(View);
